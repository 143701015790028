import { Flex, Icon, Temperature, Tile, Typography } from "components";
import React from "react";
import { useOutletContext } from "react-router-dom";
import { IOutletContext } from "layouts";
import { AvatarIcon, EnvelopeIcon, ExclamationTriangleIcon, PhoneIcon } from "assets";
import { ThemeModeContext } from "App";
import Skeleton from "react-loading-skeleton";
import { getIconByIconName } from "pages/OverviewPage/helpers";
import { useProducedEnergy } from "api/modules/metrics";
import { useForecastWeather } from "api/modules/weather";
import { EnergyRate } from "layouts/PageLayout/components/Menu/components";
import dayjs from "dayjs";
import { Styles } from "./styles";

const WeatherTile: React.FC<{
  title: string;
  minTemperature: number;
  maxTemperature: number;
  icon?: string | null;
  isDarkTheme: boolean;
}> = ({ minTemperature, maxTemperature, title, icon, isDarkTheme }) => {
  return (
    <Flex direction="column" align="center">
      <Styles.ItemTitle color={isDarkTheme ? "blueGrey200" : "grey500"} size={14} height={21} weight={500}>
        {title}
      </Styles.ItemTitle>
      {icon && <img src={icon} alt="weather-forecast" />}
      <Flex columnGap={8} align="center">
        <Temperature>
          <Styles.TemperatureValue size={24} height={32} weight={500} spacing={-0.5}>
            {Math.round(minTemperature)}
          </Styles.TemperatureValue>
        </Temperature>
        <Styles.ForecastDelimiter />
        <Temperature>
          <Styles.TemperatureValue size={24} height={32} weight={500} spacing={-0.5}>
            {Math.round(maxTemperature)}
          </Styles.TemperatureValue>
        </Temperature>
      </Flex>
    </Flex>
  );
};

const ProducedEnergyGridItem: React.FC<{
  title: string;
  amount: number;
  energyRate?: number | null;
  isEnergyRateLoading: boolean;
  isDarkTheme: boolean;
  isLoading: boolean;
}> = ({ title, amount, energyRate, isDarkTheme, isLoading, isEnergyRateLoading }) => {
  return (
    <Flex direction="column" rowGap={4}>
      <Styles.ItemTitle size={14} height={21} weight={500} color={isDarkTheme ? "blueGrey200" : "grey400"} noWrap>
        {title}
      </Styles.ItemTitle>
      <Flex direction="column">
        {isLoading ? (
          <Skeleton count={1} height={34} width="100%" />
        ) : (
          <Styles.EnergyValue size={28} height={34} weight={500} spacing={-0.5}>
            {Math.round(amount)}
          </Styles.EnergyValue>
        )}
        {isLoading || isEnergyRateLoading ? (
          <Skeleton count={1} height={34} width="100%" />
        ) : (
          <Typography size={12} height={16} weight={300} color={isDarkTheme ? "green200" : "green500"}>
            CHF {Math.round((energyRate || 0) * amount)}
          </Typography>
        )}
      </Flex>
    </Flex>
  );
};

export const PageFooter: React.FC<{
  energyRate?: number | null;
  isEnergyRateLoading: boolean;
}> = ({ energyRate, isEnergyRateLoading }) => {
  const { data: forecast, isLoading: isForecastLoading } = useForecastWeather();

  const monthQuery = useProducedEnergy({ startDate: dayjs().startOf("M").format("YYYY-MM-DD") });
  const yearQuery = useProducedEnergy({ startDate: dayjs().startOf("y").format("YYYY-MM-DD") });
  const totalQuery = useProducedEnergy({ startDate: "1970-01-01" });
  const energyData = [
    { query: monthQuery, title: "Diesen Monat" },
    { query: yearQuery, title: "Dieses Jahr" },
    { query: totalQuery, title: "Gesamtlaufzeit" },
  ];

  const { handleOpenMenu } = useOutletContext<IOutletContext>();

  const isDarkTheme = React.useContext(ThemeModeContext) === "dark";
  return (
    <Styles.Container>
      <Tile
        title={
          <span>
            Produzierte Energie&nbsp;
            <Typography color={isDarkTheme ? "blueGrey200" : "grey400"}>(MWh)</Typography>
          </span>
        }
        headerInfo={
          energyRate === null && (
            <Styles.EnergyRateNotification>
              <Icon icon={ExclamationTriangleIcon} size={20} color="yellow600" />
              <Typography size={14} height={21} weight={400} color="yellow800">
                Ergänzen Sie den Preis für Ihr Rückliefertarif.{" "}
                <Styles.EnergyRateNavigationLink
                  onClick={() => handleOpenMenu({ Content: EnergyRate, title: "Erlöskalkulation" })}
                >
                  Zu den Einstellungen
                </Styles.EnergyRateNavigationLink>
              </Typography>
            </Styles.EnergyRateNotification>
          )
        }
      >
        <Styles.ProducedEnergyGrid>
          {energyData.map(({ query: { isLoading, data = 0 }, title }) => (
            <ProducedEnergyGridItem
              key={title}
              title={title}
              amount={data}
              energyRate={energyRate}
              isDarkTheme={isDarkTheme}
              isLoading={isLoading}
              isEnergyRateLoading={isEnergyRateLoading}
            />
          ))}
        </Styles.ProducedEnergyGrid>
      </Tile>
      {(isForecastLoading || (forecast && forecast.length > 0)) && (
        <Tile title="Wettervorhersage">
          {isForecastLoading ? (
            <Skeleton count={4} />
          ) : (
            forecast && (
              <Styles.WeatherTileContainer columnGap={16} justify="space-between">
                {["Heute", "Morgen", "Übermorgen"].map((title, index) => {
                  return (
                    forecast[index] && (
                      <WeatherTile
                        key={title}
                        title={title}
                        minTemperature={forecast[index].min_temp}
                        maxTemperature={forecast[index].max_temp}
                        icon={getIconByIconName(forecast[index].icon)}
                        isDarkTheme={isDarkTheme}
                      />
                    )
                  );
                })}
              </Styles.WeatherTileContainer>
            )
          )}
        </Tile>
      )}
      <Tile title="Kontakt">
        <Flex columnGap={24}>
          <Flex>
            <Icon icon={AvatarIcon} />
          </Flex>
          <Flex direction="column" rowGap={16}>
            <Flex direction="column" rowGap={4}>
              <Typography size={16} weight={500} height={24}>
                Sefedin Tairi
              </Typography>
              <Typography size={14} height={21} color={isDarkTheme ? "blueGrey200" : "grey500"}>
                Leiter Service und Kleinanlagen Bachenbülach
              </Typography>
            </Flex>
            <Flex columnGap={16} wrap="wrap" rowGap={16}>
              <Styles.ContactButton variant="secondary">
                <Flex columnGap={8} align="center">
                  <Icon icon={EnvelopeIcon} color={isDarkTheme ? "blueGrey300" : "grey400"} />
                  <Typography size={14} height={20} weight={500}>
                    Email
                  </Typography>
                </Flex>
              </Styles.ContactButton>
              <Styles.ContactButton variant="secondary">
                <Flex columnGap={8} align="center">
                  <Icon icon={PhoneIcon} color={isDarkTheme ? "blueGrey300" : "grey400"} />
                  <Typography size={14} height={20} weight={500}>
                    Call
                  </Typography>
                </Flex>
              </Styles.ContactButton>
            </Flex>
          </Flex>
        </Flex>
      </Tile>
    </Styles.Container>
  );
};
