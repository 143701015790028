import { DatePicker, Flex, Tabs, Typography } from "components";
import React from "react";
import dayjs, { ManipulateType } from "dayjs";
import { TimeRangeId, timeRanges } from "constants/time-range";
import { ReactDatePickerProps } from "react-datepicker";
import { useChartsData } from "api/modules/metrics";
import Skeleton from "react-loading-skeleton";
import { PickerDateRangeType, PickerDateType, getChartLabels, getDatePickerInputValue, getDates } from "./helpers";
import { DoughnutChart, EnergyBalanceChart } from "./charts";
import { Styles } from "./styles";

export const EnergyCharts: React.FC<{ isBatteryConnected: boolean }> = ({ isBatteryConnected }) => {
  const [timeRange, setTimeRange] = React.useState(timeRanges[0].value);
  const [startDate, setStartDate] = React.useState<PickerDateType>(dayjs().startOf("d").toDate());
  const [endDate, setEndDate] = React.useState<PickerDateType>(dayjs().endOf("d").toDate());

  const isDaySelected = timeRange === TimeRangeId.Day;
  const format = "YYYY-MM-DD HH:mm:ssZ";
  const { data: chartsData, isLoading } = useChartsData({
    startDate: dayjs(startDate).format(format),
    endDate: endDate ? dayjs(endDate).format(format) : undefined,
    daily: isDaySelected,
  });

  const handleDatePickerChange = (tRange: TimeRangeId, date?: PickerDateType | PickerDateRangeType) => {
    const [sDate, eDate] = getDates(tRange, date);
    setStartDate(sDate);
    setEndDate(eDate);
  };

  const handleTimeRangeChange = (timeRangeId: TimeRangeId) => {
    setTimeRange(timeRangeId);
    handleDatePickerChange(timeRangeId);
  };

  const handleArrowClick: (forward?: boolean) => React.MouseEventHandler<HTMLDivElement> =
    (forward?: boolean) => (event) => {
      event.stopPropagation();

      handleDatePickerChange(
        timeRange,
        (forward
          ? dayjs(startDate).add(1, timeRange as ManipulateType)
          : dayjs(startDate).subtract(1, timeRange as ManipulateType)
        ).toDate(),
      );
    };

  const chartLabels = React.useMemo(
    () => getChartLabels(timeRange, startDate, endDate),
    [timeRange, startDate, endDate],
  );

  return (
    <Styles.Container direction="column" rowGap={24}>
      <Flex direction="column" rowGap={24}>
        <Styles.TitleContainer justify="space-between" align="center">
          <Typography size={22} height={32} weight={500}>
            Energiebilanz
          </Typography>
          {/* <Icon icon={FullScreenIcon} onClick={() => console.log("clicked")} /> */}
        </Styles.TitleContainer>
        <Tabs items={timeRanges} selectedItem={timeRange} onChange={handleTimeRangeChange} />
      </Flex>
      <Styles.ChartsContainer columnGap={48}>
        <Flex direction="column" rowGap={16} width="100%">
          <Flex direction="column" rowGap={24}>
            <DatePicker
              onChange={(date) => handleDatePickerChange(timeRange, date)}
              startDate={startDate}
              endDate={endDate}
              inputValue={getDatePickerInputValue(timeRange, startDate, endDate)}
              showMonthYearPicker={timeRange === TimeRangeId.Month}
              showYearPicker={timeRange === TimeRangeId.Year}
              handleNext={timeRange === TimeRangeId.Custom ? undefined : handleArrowClick(true)}
              handlePrev={timeRange === TimeRangeId.Custom ? undefined : handleArrowClick(false)}
              maxDate={new Date()}
              shouldCloseOnSelect={timeRange !== TimeRangeId.Custom}
              selectsRange={(timeRange === TimeRangeId.Custom) as unknown as ReactDatePickerProps["selectsRange"]}
              useWeekSelection={timeRange === TimeRangeId.Week}
            />
          </Flex>
          {isLoading ? (
            <Skeleton count={20} />
          ) : (
            <EnergyBalanceChart
              labels={chartLabels}
              timeRange={timeRange}
              chartsData={chartsData}
              isBatteryConnected={isBatteryConnected}
            />
          )}
        </Flex>
        {isLoading ? (
          <Skeleton count={22} width={152} />
        ) : (
          chartsData && (
            <Styles.DoughnutChartContainer direction="column" width="152px" justify="space-between">
              <DoughnutChart
                title="Erzeugung"
                items={[
                  { value: chartsData.donuts.production.solar, label: "Eigenverbrauch", color: "#147D64" },
                  { value: chartsData.donuts.production.grid, label: "Netzeinspeisung", color: "#65D6AD" },
                ]}
                innerValue={chartsData.donuts.production.solar}
              />
              <DoughnutChart
                title="Verbrauch"
                items={[
                  { value: chartsData.donuts.consumption.solar, label: "Autarkie", color: "#127FBF" },
                  { value: chartsData.donuts.consumption.grid, label: "Netzbezug", color: "#DC5055" },
                ]}
                innerValue={chartsData.donuts.consumption.solar + chartsData.donuts.consumption.grid}
              />
            </Styles.DoughnutChartContainer>
          )
        )}
      </Styles.ChartsContainer>
    </Styles.Container>
  );
};
