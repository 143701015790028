import React from "react";

export const DefaultHouseLogoIcon: React.FC = () => {
  return (
    <svg width="42" height="42" viewBox="0 0 140 132" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M54.7935 59.4722V66.9062H34.9082V80.6025H58.1626V88H25.2769V40.0264H58.0894V47.4238H34.9082V59.4722H54.7935ZM64.772 40.0264H77.333L89.5645 75.3657H89.7842L102.089 40.0264H114.687V88H105.055V73.6812L105.971 52.5142L105.788 52.4775L92.9336 88H86.4883L73.6709 52.5874L73.4878 52.624L74.4033 73.6812V88H64.772V40.0264Z"
        fill="#5B7B92"
      />
      <rect x="4.5" y="0.5" width="131" height="131" stroke="#B9B2A8" strokeWidth="3" />
    </svg>
  );
};
