import React from "react";
import { Flex, Typography } from "components";
import { styled } from "styled-components";
import { PdfRef } from "./PdfRef";
import { IHelpChatSource } from "types/common";

const MessageContainer = styled(Flex)<{ $me?: boolean }>`
  padding: 16px;
  background-color: ${(props) => (props.$me ? props.theme.colors.green200op30 : props.theme.colors.blueGrey100)};
  border-radius: 6px;
  max-width: 70%;

  #dark-theme & {
    color: ${(props) => props.theme.colors.white};
    background: ${(props) => props.theme.colors.blueGrey700};
  }
`;

const PdfItems = styled.ul`
  margin: 0;
  padding-left: 16px;

  > li {
    display: flex;
  }
`;

export type HelpMessage = {
  text: string;
  me?: boolean;
  sources?: IHelpChatSource[];
};

export const ChatItem: React.FC<{ item: HelpMessage }> = ({ item }) => {
  return (
    <MessageContainer $me={item.me} direction="column" rowGap={32} alignSelf={item.me ? "flex-end" : "flex-start"}>
      <Typography>{item.text}</Typography>
      <PdfItems>
        {item.sources?.map((source, index) => (
          <li key={index}>
            <Flex columnGap={24}>
              <span>•</span>
              {source.source}
            </Flex>
            <Flex columnGap={4}>
              &nbsp;{"("}
              {source.pages.map((page) => (
                <PdfRef key={page} page={page} pdfFile={source.pdfLink} />
              ))}
              {")"}
            </Flex>
          </li>
        ))}
      </PdfItems>
    </MessageContainer>
  );
};
