import React, { useEffect, useState } from "react";
import { useDeviceById } from "api/modules/devices";
import { Button, Flex, Input, Typography } from "components";
import { useSendMessages } from "api/modules/help";
import Skeleton from "react-loading-skeleton";
import { styled } from "styled-components";
import { SmartHelpIcon } from "assets";
import { useNavigate, useOutletContext, useSearchParams } from "react-router-dom";
import { IOutletContext } from "layouts";
import { Routes } from "constants/routes";
import { ChatItem, HelpMessage } from "./components";

const TitleContainer = styled(Flex)`
  gap: 8px;
  padding-bottom: 24px;
  border-bottom: 1px solid ${(props) => props.theme.colors.grey100};

  #dark-theme & {
    border-bottom: 1px solid ${(props) => props.theme.colors.clickBlue};
  }
`;

const ServiceContainer = styled(Flex)`
  flex-direction: column;
  gap: 16px;
  padding: 16px;
  border: 1px solid ${(props) => props.theme.colors.grey100};
  width: fit-content;
  max-width: 450px;

  #dark-theme & {
    border: 1px solid ${(props) => props.theme.colors.blueGrey600};
  }
`;

const ChatContainer = styled(Flex)`
  flex-direction: column;
  gap: 32px;
  background-color: ${(props) => props.theme.colors.white};
  padding: 48px 24px;

  #dark-theme & {
    background-color: ${(props) => props.theme.colors.blueGrey600};
  }
`;

export const HelpPage: React.FC = () => {
  const { mutate: sendMessages, isLoading, data } = useSendMessages();
  const { alarms } = useOutletContext<IOutletContext>();

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const alarmId = searchParams.get("alarm_id");
  const deviceId = searchParams.get("device_id");

  const { data: device } = useDeviceById(deviceId!!);
  const modelId = device ? device.model_id : null;

  const [messages, setMessages] = useState<HelpMessage[]>([]);
  const [questionText, setQuestionText] = useState("");

  useEffect(() => {
    const alarm = alarms?.find((al) => alarmId && al.alarm_id === +alarmId);
    if (modelId && alarm) {
      sendMessages({
        question: alarm.msg,
        modelId,
      });

      setMessages([{ text: alarm.msg, me: true }]);
    }
  }, [modelId, alarms, alarmId]);

  useEffect(() => {
    if (data) {
      setMessages((msgs) => [...msgs, { text: data.answer, sources: data.sources }]);
    }
  }, [data]);

  const sendQuestion: React.KeyboardEventHandler<HTMLInputElement> = (event) => {
    if (event.key === "Enter" && modelId) {
      setMessages((msgs) => [...msgs, { text: questionText, me: true }]);
      setQuestionText("");
      sendMessages({
        question: questionText,
        modelId,
      });
    }
  };

  const handleServiceArrange = () => {
    navigate(`${Routes.Service}?alarm_id=${alarmId}`);
  };

  return (
    <Flex direction="column" rowGap={32}>
      <TitleContainer>
        <Typography size={14} height={21} weight={400} color="grey500">
          Smarte Soforthilfe
        </Typography>
        <SmartHelpIcon />
      </TitleContainer>
      <ChatContainer>
        {messages.map((message, index) => (
          <ChatItem item={message} key={index} />
        ))}
        {isLoading && <Skeleton count={4} />}

        <Input
          value={questionText}
          placeholder="Fragen Sie mehr..."
          onChange={setQuestionText}
          onKeyDown={sendQuestion}
          disabled={isLoading}
        />
      </ChatContainer>

      <ServiceContainer>
        <Typography>Keine Antwort gefunden? Kontaktieren Sie unsere Servicetechniker.</Typography>
        <Button size="fit" variant="primary" onClick={handleServiceArrange}>
          Servicetermin vereinbaren
        </Button>
      </ServiceContainer>
    </Flex>
  );
};
